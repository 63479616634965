.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.clickable {
  cursor: pointer;
  height: 68px;
}

.users-table-checkbox .chakra-checkbox__control {
  border: 2px solid black !important;
}

.chakra-checkbox__control {
  border-color: #a6a6a6 !important;
}

.react-datepicker-popper {
  z-index: 667;
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

body {
  font-family: "Inter", sans-serif !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #b9babe #e9ebef;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #e9ebef;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #b9babe;
}
